import { Controller } from "stimulus";
import { GeniusClient } from "cayan";
import { forEach } from "xregexp";

/*
 * Base library:
 *   https://github.com/cohubinc/cayan-js
 * CurrentScreen values:
 *   https://docs.tsysmerchant.com/knowledge-base/faqs/what-are-the-genius-status-call-possible-responses
 */
export default class extends Controller {
  static targets = ['lineItem']

  async initialize() {
    let controller = this;
    if (document.documentElement.hasAttribute("data-turbo-preview")) return;

    let orderTax = parseFloat(controller.context.element.dataset.orderTax);
    let orderTotal = parseFloat(controller.context.element.dataset.orderTotal);

    controller.genius = await GeniusClient.createInstance(controller.geniusConfig).catch(e => {
      alert('There was a problem with the terminal: ' + e.message);
    });
    controller.orderNumber = controller.context.element.dataset.orderNumber;

    await controller.genius.StartOrder(controller.orderNumber);

    controller.targetItemIds = {};
    await controller.lineItemTargets.forEach((lineItemTarget) => {
      controller.addLineItemTargetToGenius(lineItemTarget, controller.genius, orderTax, orderTotal)
    });

    controller.context.element.addEventListener('ced:line-item-updated', async function (e) {
      let targetItemId = controller.targetItemIds[event.detail.id];
      let data = {
        Order: controller.orderNumber,
        Type: "Sku",
        TypeValue: event.detail.sku,
        Quantity: event.detail.quantity,
        Description: event.detail.description.substring(0, 35),
        Amount: event.detail.unitCost.toFixed(2),
        OrderTotal: event.detail.orderTotal.toFixed(2),
        UPC: event.detail.barcode,
        TaxAmount: 0.0,
        OrderTax: event.detail.orderTax.toFixed(2),
        Category: 'None'
      };

      if (targetItemId) {
        data['TargetItemID'] = targetItemId;

        if (data.Quantity > 0) {
          await controller.genius.UpdateItem(data);
        } else {
          await controller.genius.DeleteItem({
            Order: controller.orderNumber,
            TargetItemID: targetItemId,
            OrderTotal: event.detail.orderTotal.toFixed(2),
            OrderTax: event.detail.orderTax.toFixed(2)
          });
        }
      } else {
        await controller.genius.AddItem(data);
      }
    }, false);
  }

  async disconnect() {
    if (this.genius && !this.startedCheckout) await this.genius.Cancel();
  }

  checkout(event) {
    const controller = this;

    controller.startedCheckout = true; // avoid cancel
    Rails.ajax({
      type: 'POST',
      url: event.currentTarget.dataset.createPaymentUrl,
      error: function () { controller.startedCheckout = false; }
    })
  }

  async checkoutOther() {
    const controller = this;

    controller.startedCheckout = true; // avoid cancel
    await this.genius.EndOrder(controller.orderNumber, 'Other');
  }

  async updateTotal() {
    const controller = this;
    if (!controller.genius) return;

    let orderTax = parseFloat(controller.context.element.dataset.orderTax);
    let orderTotal = parseFloat(controller.context.element.dataset.orderTotal);

    await controller.genius.UpdateTotal({
      Order: controller.orderNumber,
      OrderTotal: orderTotal.toFixed(2),
      OrderTax: orderTax.toFixed(2)
    });
  }

  async addLineItemTargetToGenius(lineItemTarget, genius, orderTax, orderTotal) {
    let controller = this;

    let response = await genius.AddItem({
      Order: controller.orderNumber,
      Type: "Sku",
      TypeValue: lineItemTarget.dataset.sku,
      Quantity: parseInt(lineItemTarget.dataset.quantity),
      Description: lineItemTarget.dataset.description.substring(0, 35),
      Amount: parseFloat(lineItemTarget.dataset.unitCost).toFixed(2),
      OrderTotal: orderTotal.toFixed(2),
      UPC: lineItemTarget.dataset.barcode,
      TaxAmount: 0.0,
      OrderTax: orderTax.toFixed(2),
      Category: 'None'
    });
    controller.targetItemIds[lineItemTarget.dataset.id] = response.ItemID;
  }

  get geniusConfig() {
    const dataset = this.context.element.dataset;

    return {
      MerchantName: dataset.merchantName,
      MerchantSiteId: dataset.merchantSiteId,
      MerchantKey: dataset.merchantKey,
      CEDHostname: dataset.cedHostname
    };
  }
}
