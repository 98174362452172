import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['listGroup', 'selectAllCheckbox', 'itemCheckbox', 'totalCountLabel', 'currentPageSelected', 'allPagesSelected', 'includeAllPagesFlag'];

  selectAll() {
    this.itemCheckboxTargets.forEach(function(target) {
      target.checked = true;
      target.closest('.list-group-item').classList.toggle('is--selected', true);
    });

    this.itemToggled();
  }

  selectAllPages() {
    this.toggleAllPages(true);
  }

  unselectAll() {
    this.itemCheckboxTargets.forEach(function(target) {
      target.checked = false;
      target.closest('.list-group-item').classList.toggle('is--selected', false);
    });

    this.itemToggled();
  }

  unselectAllPages() {
    this.toggleAllPages(false);
  }

  toggleAllPages(status) {
    if (this.hasIncludeAllPagesFlagTarget) {
      this.includeAllPagesFlagTarget.value = status;
      this.updatePageSelectionBlockVisibility(true, status);
      this.updateTotalCountLabel(this.getSelectedCount(), status);
      this.updateTargetCheckboxes();
    } else if (this.context.element.dataset.updateTargetCheckbox) {
      this.updatePageSelectionBlockVisibility(true, status);
      this.updateTotalCountLabel(this.getSelectedCount(), status);
      this.updateTargetCheckboxes();
      if(status) {
        document.querySelectorAll(this.context.element.dataset.updateTargetCheckbox).forEach(function(checkbox) {
          checkbox.value = 'all';
        });
      }
    }
  }

  toggleAll(event) {
    event.stopImmediatePropagation();

    if (this.getSelectedCount() === this.itemCheckboxTargets.length) {
      this.unselectAll();
    } else {
      this.selectAll();
    }
  }

  itemToggled() {
    if (this.hasIncludeAllPagesFlagTarget) this.includeAllPagesFlagTarget.value = false;

    const selectedCount = this.getSelectedCount();
    const isPageSelected = selectedCount === this.itemCheckboxTargets.length;

    this.selectAllCheckboxTarget.checked = isPageSelected;
    this.updateListGroupClass(selectedCount);
    this.updateTotalCountLabel(selectedCount, false);
    this.updatePageSelectionBlockVisibility(isPageSelected, false);
    this.updateTargetCheckboxes();
  }

  getSelectedCount() {
    return this.itemCheckboxTargets.filter(target => target.checked).length;
  }

  updateListGroupClass(selectedCount) {
    this.context.element.classList.toggle('is--bulk', selectedCount > 0);
  }

  updateTotalCountLabel(selectedCount, isAllPagesSelected) {
    if (isAllPagesSelected) {
      this.totalCountLabelTarget.innerHTML = `${this.context.element.dataset.totalCount} selected`;
    } else {
      this.totalCountLabelTarget.innerHTML = `${selectedCount} selected`;
    }
  }

  updatePageSelectionBlockVisibility(isPageSelected, isAllPagesSelected) {
    if (!this.hasCurrentPageSelectedTarget) return;

    this.currentPageSelectedTarget.classList.toggle('d-none', !isPageSelected || isAllPagesSelected);
    this.allPagesSelectedTarget.classList.toggle('d-none', !isAllPagesSelected);
  }

  updateTargetCheckboxes() {
    if (!this.context.element.dataset.updateTargetCheckbox) return;

    const ids = this.itemCheckboxTargets.filter(target => target.checked).map(item => item.value).join(',');
    document.querySelectorAll(this.context.element.dataset.updateTargetCheckbox).forEach(function(checkbox) {
      checkbox.value = ids;
    });
  }
}
