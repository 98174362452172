import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['scrollable']

  scroll() {
    this.scrollableTarget.scroll({
     top: 0,
     left: 0,
     behavior: 'smooth'
    });
  }
}
