import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['select', 'submit'];

  connect() {
    this.initialSelectValue = this.selectTarget.value
    this.submitTarget.setAttribute("disabled", "true")
  }

  quantityUpdated(){
    setTimeout(() => {
      // New action executed after all existing click handlers.
      if(this.selectTarget.value == this.initialSelectValue){
        this.submitTarget.setAttribute("disabled", "true")
      }else{
        this.submitTarget.removeAttribute("disabled")
      }
    }, 0);
  }
}
