import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['quantitySelect', 'totalQuantityDisplay'];

  updateQuantity() {
    if (!this.totalQuantityDisplayTarget) return;

    let sum = this.quantitySelectTargets
                  .map(select => parseInt(select.value))
                  .reduce((sum, x) => sum + x);

    this.totalQuantityDisplayTargets.forEach(target => target.hidden = (sum == 0));
  }
}
