import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['activity', 'customers','customerId','individualCustomerID']

  connect() {
    setTimeout(() => {
      const submitButton = this.element.querySelector("input[type='submit']");
      submitButton.removeAttribute("disabled");
    }, 0);
  }

  perform(event){
    this.showLoader();
    setTimeout(() => {
      //search logic here...
      let keyword = event.target.value
      let linkEle = document.getElementById("search-customer-link")
      if(linkEle){
        let link = linkEle.getAttribute("href") 
        let updatedLink = this.updateOrCreateQueryParam(link, 'query', keyword)
        linkEle.setAttribute("href", updatedLink)
        linkEle.click()
      }
      // Hide the loader after the search is complete
      this.hideLoader();
    }, 1000); // Simulating a 1-second delay for the search
  }

  updateOrCreateQueryParam(url, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = url.indexOf("?") !== -1 ? "&" : "?";

    if (url.match(re)) {
        // If the query parameter already exists, update its value
        return url.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        // If the query parameter doesn't exist, create it
        return url + separator + key + "=" + value;
    }
  }

  showLoader(){
    this.activityTarget.hidden = false;
  }

  hideLoader() {
    this.activityTarget.hidden = true;
  }

  updateIndividualCustomer(){
    let linkElements = document.getElementsByClassName("custom-hidden-field")
    if(linkElements){
      Array.from(linkElements).forEach(subMenu => {
        subMenu.value = null;
      });
    }
  }

  selectCompanyContact(event){
    let individual_customer_id = event.currentTarget.getAttribute('data-value')
    this.individualCustomerIDTarget.value = individual_customer_id
  }
}

