import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    url: String
  };

  show(event) {
    const controller = this;

    event.preventDefault();
    event.stopImmediatePropagation();

    $('.page-alerts').remove();

    if (controller.modal && controller.data.get('persist')) {
      return window.modalShow(controller.modal);
    }

    var url = controller.data.get('url');
    var paymentForm = $("#payment_form");
    if (paymentForm.length == 1) {
      let form_data = new FormData(paymentForm[0]);
      for (let [name, value] of Array.from(form_data.entries())) {
        if (value === '') form_data.delete(name);
      }
      let form_str = new URLSearchParams(form_data).toString();
      url = url + "&" + form_str
    }

    fetch(url).then((r) => r.text()).then((html) => {
      controller.modal = window.addModalAndShow(html);

      $(controller.modal).on('hidden.bs.modal', function(e) {
        $(controller.modal).modal('dispose');
        $(controller.modal).remove();
        controller.modal = undefined;
      })
    });
  }

  remove() {
    if (!this.modal) return;

    $(this.modal).modal('hide');
  }

  disconnect() {
    if (!this.modal) return;

    $(this.modal).modal('dispose');
  }
}
