import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['printerSelect', 'paperSizeSelect'];

  connect () {
    this.handlePrinterChange();
  }

  handlePrinterChange() {
    this.populatePaperSize(this.printerSelectTarget.value);
  }

  populatePaperSize(printerId) {
    const selectBox = this.paperSizeSelectTarget;
    selectBox.innerHTML = '';
    console.log(this.context.element.dataset.sizes)
    var newSizes = JSON.parse(this.context.element.dataset.sizes)[printerId]

    newSizes.forEach(item => {
      const opt = document.createElement('option');
      opt.value = item;
      opt.innerHTML = item;

      selectBox.appendChild(opt);
    })
  }
}
