import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  showForm(event) {
  	event.preventDefault();
    const existing_individual = document.getElementById("existing_individual");
    const create_individual = document.getElementById("create_individual");

    existing_individual.hidden = false;
    create_individual.hidden = true;

    const currentUrl = window.location.href;
    const newUrl = currentUrl.replace("/contacts/new", "");

    // Replace the current URL without reloading the page
    history.replaceState({}, "", newUrl);
  }
}
