import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    window.addEventListener('scanning', this.scan);
  }

  disconnect() {
    window.removeEventListener('scanning', this.scan);
  }

  scan(event) {
    var code = event.value.replace('/', '').replace(' ', '').replace(',', '').replace('"', '').replace("'", '').replace(".", '').replace("&", '');
    var scanObj = window.scan.cameraScanner();

    if ($(`#pack-modal-${code}`).length > 0) {
      $(`#pack-modal-${code}`).modal('show');
    } else {
      scanObj.playErrorAudio();
      alert('Item not in fulfillment');
    }

    scanObj.restart();
  }

  get packItemUrl() {
    return this.context.element.dataset.packItemUrl;
  }
}
