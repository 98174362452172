import { Controller } from "stimulus";
import CableReady from 'cable_ready';
import consumer from '../channels/consumer';

export default class extends Controller {
 connect() {
    const that = this;

    consumer.subscriptions.create({
      channel: 'PurchaseOrderChannel',
      id: that.context.element.dataset.id
    }, {
      received(data) {
        if (data.cableReady) {
          CableReady.perform(data.operations)
        }
      }
    })
  }
}
