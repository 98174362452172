import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    window.globalBarcode = this;
    window.addEventListener('scanning', this.scan);
  }

  disconnect() {
    window.removeEventListener('scanning', this.scan);
  }

  scan(e) {
    var evt = event || window.event;

    if (!evt) return; // might be froala editor
    if (evt.target.tagName == 'INPUT') return;
    if (evt.target.tagName == 'TEXTAREA') return;
    if (evt.target.tagName == 'TRIX-EDITOR') return;

    var scanObj = window.scan.cameraScanner();
    scanObj.restart();

    Turbo.visit(window.globalBarcode.onBarcodeScanUrl.replace('__BARCODE__', e.value));
  }

  get onBarcodeScanUrl() {
    return this.context.element.dataset.onBarcodeScanUrl;
  }
}
