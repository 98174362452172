import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'input'];

  toggle() {
    if (this.shouldBeSelected) {
      this.inputTarget.value = 0;
    } else {
      this.inputTarget.value = this.element.dataset.quantityToReceive;
    }

    this.element.dispatchEvent(new Event('keydown'));
  }

  refresh() {
    this.checkboxTarget.checked = this.shouldBeSelected;
  }

  get shouldBeSelected() {
    return this.inputTarget.value >= this.element.dataset.quantityToReceive;
  }
}
