// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import 'alpinejs'
import Rails from '@rails/ujs'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.consumer = consumer

StimulusReflex.initialize(application, { consumer, isolate: true, debug: process.env.RAILS_ENV === 'development' })

import debounced from 'debounced'
debounced.initialize({ input: { wait: 400 } })

window.Rails = Rails;
$.ajaxSetup({
  headers: {
    'X-CSRF-Token': Rails.csrfToken()
  }
});

// show progress indicator sooner
Turbo.navigator.delegate.adapter.showProgressBarAfterDelay = function () {
  // Save the timeout reference to allow clearing it later
  this.progressBarTimeout = setTimeout(() => {
    this.progressBar.show();
  }, 10);
};

document.addEventListener('page:loaded', function () {
  document.dispatchEvent(new Event('turbo:load'));
  document.dispatchEvent(new Event('ajax:complete'));
})
document.addEventListener('stimulus-reflex:before', event => {
  window.safeStartTurboProgress();
})
document.addEventListener('stimulus-reflex:after', event => {

  window.safeStopTurboProgress();

  var end = document.location.href.indexOf('?') === -1 ? document.location.href.length : document.location.href.indexOf('?');
  var currentUrl = document.location.href.slice(0, end);

  if (window.eventUrl) {
    var eventUrl = window.eventUrl.slice(0, window.eventUrl.indexOf('?'));
  }

  if (currentUrl === eventUrl) {
    history.pushState({ turbo: true, url: window.eventUrl }, '', window.eventUrl);
  }
})
document.addEventListener('ajax:before', function () {
  window.safeStartTurboProgress();
});
document.addEventListener('ajax:complete', function () {
  window.safeStopTurboProgress();
});
document.addEventListener('turbo:push-history', function (event) {
  window.eventUrl = event.detail.url;
});

$(document).on('turbo:before-cache', function () {
  document.querySelectorAll('[data-turbo-temporary], .invalid-feedback, #toast-container').forEach((element) => {
    element.style.display = 'none';
  });

  window.clearEphemeralElements();

  $('.modal.show').modal('hide');
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open');
});


document.addEventListener("turbo:frame-missing", (event) => {
  if (event.detail.response.ok) {
    console.log("This page visit navigated via turbo:frame-missing event.")
    event.preventDefault()
    event.detail.visit(event.detail.response.url)
  }
})
