import { Controller } from "stimulus";

export default class extends Controller {
  connect () {
    window.addEventListener('scanning', this.scan);
  }

  disconnect() {
    window.removeEventListener('scanning', this.scan);
  }

  scan(event) {
    // 36 is the uuid lenght
    var code = event.value.slice(-36);
    console.log(code);
    var preview_link = $('.items-preview-link').html().replace(/ /g,'');
    preview_link = preview_link.concat(`?warehouse_tote_id=${code}`);
    $.get( preview_link, ( data ) => {
      location.reload();
    });
  }
}
