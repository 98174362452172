import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    window.addEventListener('scanning', this.scan);
  }

  disconnect() {
    window.removeEventListener('scanning', this.scan);
  }

  scan(event) {
    if (window.selected_source) {
      var id = window.selected_source;
      $(`#warehouse-rerceipt-scanner-form-source-${id}`).find('.scan_sku').val(event.value);
      $(`#warehouse-rerceipt-scanner-form-source-${id} .form-submit`).trigger('click');
    }
  }
}
