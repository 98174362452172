import { Controller } from "stimulus";

export default class extends Controller {
  close(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    document.querySelector('.split-panels-view[data-panel="main"]')
            .classList
            .remove('is--active');
    document.querySelector('.split-panels-view[data-panel="side"]')
            .classList
            .add('is--active');
    document.querySelector('.split-panels-view[data-panel="main"] .page-inner')
            .innerHTML = '';

    window.setActiveListGroupItem();

    if(this.data.get('closeUrl')) {
      history.pushState({}, "", this.data.get('closeUrl'));
    }
  }
}
