import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const that = this;

    var options = $(that.element).data();
    options.disable = options.disables || [];
    if (options.updatemindate) {
      options.onChange = function(selectedDates, dateStr, instance) {
        document.querySelector(options.updatemindate)._flatpickr.set('minDate', dateStr);
      }
    }
    window.flatpickr(that.element, options);
  }
}
