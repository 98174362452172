import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    window.addEventListener('scanning', this.scan);
  }

  disconnect() {
    window.removeEventListener('scanning', this.scan);
  }

  scan(event) {
    if (window.selected_tote) {
      var id = window.selected_tote;
      $(`#warehouse-rerceipt-scanner-form-${id}`).find('.scan_sku').val(event.value);
      $(`#warehouse-rerceipt-scanner-form-${id} .form-submit`).trigger('click');
    } else {
      $("#warehouse-rerceipt-scanner-form").find('.scan_sku').val(event.value);
      $("#warehouse-rerceipt-scanner-form .form-submit").trigger('click');
    }
  }
}
