import { Controller } from "stimulus"
import Timer from "easytimer.js"

export default class extends Controller {
  connect() {
    const controller = this;

    controller.timer = new Timer();
    controller.timer.start({
      startValues: {
        seconds: parseInt(controller.context.element.dataset.secondsElapsed)
      }
    });

    controller.renderTimeElapsed();
    controller.timer.addEventListener('secondsUpdated', function() {
      controller.renderTimeElapsed();
    });
  }

  renderTimeElapsed() {
    this.context.element.innerHTML = this.timer.getTimeValues().toString();
  }
}
