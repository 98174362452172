import { Controller } from "stimulus";
import CableReady from 'cable_ready';
import consumer from '../channels/consumer';

export default class extends Controller {
  connect() {
    consumer.subscriptions.create({
      channel: 'SalesOrdersChannel',
      account_id: this.context.element.dataset.accountId
    }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }
}
