
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modalIndicator", "modal", "closeBtn" ]
  connect() {
    document.addEventListener('click', e => {
      this.modalIndicatorTarget.value = "none"
      let modalContainer = document.getElementById('modal-container')
      if(modalContainer) {
        let isClickedInsideModal = modalContainer.contains(e.target);

        if (isClickedInsideModal) {
          let isSubmitClicked = e.target.getAttribute("type") == "submit"
          let preventModalElement = e.target.classList.contains("prevent-modal-close");
          if(isSubmitClicked || preventModalElement) {
            this.modalIndicatorTarget.value = "submitted";
          }
        }else {
          this.closeBtnTarget.click();
        }
      }
    });
  }

  closeClick(){
    this.modalIndicatorTarget.value = "none";
  }
}
