import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input","modalContent"];

  connect() {
    document.addEventListener("keydown", this.handleKeyPress.bind(this));
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  submitForm() {
    if (event.key == "Meta" || event.key == "ArrowDown" || event.key == "ArrowUp") return;
    event.preventDefault();
    clearTimeout(this.timeout);
    const loading = document.getElementById('loading')
    loading.style.display = 'flex';
    const globalResults = document.getElementById('global-results')
    globalResults.style.display = 'none';
    const searchForm = document.getElementById('global-search-form')
    this.timeout = setTimeout(() => {
      if (document.contains(searchForm)) {
        searchForm.requestSubmit();
      }
    }, 400); // Adjust the delay as needed
  }

  showSearch(){
    document.getElementById('global-content').classList.remove("d-none");
    $('#global-search-input').focus();
  }

  closeSearch(){
    const element = document.getElementById('global-content')

    if (!element.classList.contains("d-none")) {
      document.getElementById('global-content').classList.add("d-none");
      // Reset global search modal
      const globalInput = document.getElementById("global-search-input");
      if (document.contains(globalInput)) {
        globalInput.value = '';
      }
      $('#more-results').empty();
      const searchForm = document.getElementById('global-search-form');
      if (document.contains(searchForm)) {
        searchForm.requestSubmit();
      }
    }
  }

  handleKeyPress(event) {
    if(event.key == "ArrowDown" || event.key == 'ArrowUp'){
      const focusElem = document.querySelector(':focus');
      const tabElements = [...document.querySelectorAll('#global-search-input, #global-results .list-group-item')];
      const tabElementsCount = tabElements.length - 1;
      if (!tabElements.includes(focusElem)) return;
      event.preventDefault();
      const focusIndex = tabElements.indexOf(focusElem);
      let elemToFocus;
      if (event.key === 'ArrowUp') elemToFocus = tabElements[focusIndex > 0 ? focusIndex - 1 : tabElementsCount];
      if (event.key === 'ArrowDown') elemToFocus = tabElements[focusIndex < tabElementsCount ? focusIndex + 1 : 0];
      elemToFocus.focus();
    }
    if ((event.metaKey || event.ctrlKey) && event.key === "j") {
      this.showSearch();
    }
    else if (event.key === "Escape") {
      this.closeSearch();
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeyPress.bind(this));
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  handleClickOutside(event) {
    if (event.target.matches("#search-input")) {
      this.showSearch();
    }
    else if (!this.modalContentTarget.contains(event.target) && event.target.id != 'load-more' && event.target.id != 'select_search' && !event.target.classList.contains('remove_recent')) {
      this.closeSearch();
    }
  }

}
