import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    if (document.getElementById('transaction-status').dataset.status == 'pending') {
      this.timeout = setTimeout(() => {
        this.redirect();
      }, 1000);
    }
  }

  redirect() {
    document.getElementById('refresh-link').click();
    if (document.getElementById('transaction-status').dataset.status == 'pending') {
      this.timeout = setTimeout(() => {
        this.redirect();
      }, 1000);
    }
  }

  cancel() {
    document.getElementById('cancel_loading').classList.remove("d-none");
    clearTimeout(this.timeout);
    Turbo.visit(this.context.element.dataset.cancelUrl, { action: 'replace' });
  }
}
