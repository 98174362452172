import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (!this.mobileListener()) return;

    document.body.classList.add("is--mobile");

    if (this.isAndroid()) {
      document.body.classList.add("is--android");
    } else {
      document.body.classList.add("is--ios");
    }
  }

  postMessage() {
    const mobileListener = this.mobileListener();
    if(!mobileListener) return;

    let msg = this.element.dataset;
    msg.key = this.element.dataset.mobile;

    if (this.isAndroid()) {
      Android.postMessage(JSON.stringify(msg));
    } else {
      webkit.messageHandlers.skutallyMobile.postMessage({ key: msg.key, url: msg.url });
    }
  }

  isAndroid() {
    return (typeof Android) !== undefined && (typeof Android) !== 'undefined';
  }

  mobileListener() {
    if ((typeof Android) !== undefined && (typeof Android) !== 'undefined') {
      return Android;
    } else if ((typeof webkit) !== undefined && (typeof webkit) !== 'undefined') {
      return webkit.messageHandlers.skutallyMobile;
    } else {
      return undefined;
    }
  }
}
