import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.initAlpineElements();

    this.selectItem()
  }

  selectItem(){
    try {
      const alpineContainers = document.querySelectorAll('.alpine-container');
      alpineContainers.forEach((div) => {
        let selectedValue = div.getAttribute('data-selected')
        if(selectedValue=="true"){
          div.click()
        }
      });
    } catch(err) {
      console.log("ERROR: "+err)
    }
  }
}


