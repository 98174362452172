import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['itemCheckbox', 'destroyCheckbox']

  markDestroy() {
    const selectedIds = this.getSelectedIds();
    this.destroyCheckboxTargets
        .filter(item => selectedIds.includes(item.dataset.id))
        .forEach(item => {
          const listGroupItem = item.closest('.list-group-item');

          item.value = true;
          listGroupItem.classList.add('d-none');
          listGroupItem.querySelector('[name="line_items_ids[]"]').checked = false;
        });

    if (document.querySelector('.is--bulk')) document.querySelector('.is--bulk').classList.remove('is--bulk');
    $('form.dirtylisten').dirtyForms('rescan');
  }

  getSelectedIds() {
    return this.itemCheckboxTargets.filter(target => target.checked).map(item => item.dataset.id);
  }
}
