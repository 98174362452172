import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['quantityInput', 'minusButton', 'plusButton','adjustmentInput']

  connect() {
    this.submitTimer = null;
    this.originalQuantity = parseInt(this.context.element.dataset.originalQuantity, 10) || 0;
    this.quantityInputTarget.addEventListener('input', this.validateInput.bind(this));
    this.quantityInputTarget.addEventListener('change', this.validateInput.bind(this));
    this.quantityInputTarget.addEventListener('input', this.debounceUpdateAdjustmentFromQuantity.bind(this));
    this.quantityInputTarget.addEventListener('change', this.debounceUpdateAdjustmentFromQuantity.bind(this));
    this.adjustmentInputTarget.addEventListener('input', this.debounceUpdateQuantityFromAdjustment.bind(this));
    this.adjustmentInputTarget.addEventListener('change', this.debounceUpdateQuantityFromAdjustment.bind(this));
  }

  debounceUpdateQuantityFromAdjustment() {
    // Clear the previous timeout to debounce the request
    clearTimeout(this.debounceTimer);
    // Adjust delay as needed (300ms here)
    this.debounceTimer = setTimeout(() => this.updateQuantityFromAdjustment(), 500);
  }

  debounceUpdateAdjustmentFromQuantity() {
    // Clear the previous timeout to debounce the request
    clearTimeout(this.debounceTimer);
    // Adjust delay as needed (300ms here)
    this.debounceTimer = setTimeout(() => this.updateAdustmentFromQuantity(), 500);
  }

  // Debounce the input validation to prevent immediate server hits
  debounceValidateInput(event) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => this.validateInput(event), 500); // Adjust delay as needed
  }

  validateInput(event) {
    const input = event.target;
    const value = input.value;
    const filteredValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    if (value !== filteredValue) {
      input.value = filteredValue;
    }
  }

  decrement() {
    let quantity = parseInt(this.adjustmentInputTarget.value, 10) || 0;
    quantity -= 1;
    this.updateQuantity(quantity);
  }

  increment() {
    let quantity = parseInt(this.adjustmentInputTarget.value, 10) || 0;
    quantity += 1;
    this.updateQuantity(quantity);
  }

  updateQuantity(quantity) {
    this.adjustmentInputTarget.value = quantity;
    this.adjustmentInputTarget.dispatchEvent(new Event('change'));
  }

  debounceSubmitForm() {
    // Clear the previous timeout to debounce the request
    clearTimeout(this.submitTimer);
    this.submitTimer = setTimeout(() => this.submitForm(), 500); // Adjust delay as needed
  }

  submitForm() {
    const form = this.element.closest('form');
    if (form) {
      form.requestSubmit();
      this.quantityInputTarget.focus();
    }
  }

  updateQuantityFromSave(event) {
    this.submitForm();
  }

  cancel() {
    $('.ajustment_float').hide();
  }

  updateQuantityFromAdjustment() {
    const adjustmentQuantity = parseInt(this.adjustmentInputTarget.value, 10) || 0;

    // Calculate new quantity
    const newQuantity = this.originalQuantity + adjustmentQuantity;
    this.quantityInputTarget.value = newQuantity
  }

  updateAdustmentFromQuantity() {
    const quantity = parseInt(this.quantityInputTarget.value, 10) || 0;

    // Calculate new quantity
    const newAdjustment = quantity - this.originalQuantity;
    this.adjustmentInputTarget.value = newAdjustment
  }

  openModal(event) {
    event.preventDefault();
    const targetId = event.target.dataset.targetId;  // Get the unique modal ID from data-target_id
    const modal = document.getElementById(targetId); 

    if (modal) {
      modal.style.display = "block";  
      modal.classList.add("show");
    }
  }
}
