import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, name: String }

  navigate(event) {
    if (!event.shiftKey) return;
    if (!window.open || event.metaKey) return;

    let name = `${this.data.get('nameValue')}${Date.now()}`
    if (event.shiftKey) name = this.data.get('nameValue')

    event.preventDefault();

    window.open(this.data.get('urlValue'), name, this.windowFeatures)

    return false;
  }

  get windowWidth() {
    return 600
  }

  get windowHeight() {
    return Math.min(1200, screen.height * 0.85)
  }

  get leftPosition() {
    return screen.width - this.windowWidth - screen.width * 0.02
  }

  get topPosition() {
    return screen.height * 0.05
  }

  get windowFeatures() {
    let windowFeatures = {
      width: this.windowWidth,
      height: this.windowHeight,
      left: this.leftPosition,
      top: this.topPosition,
      toolbar: 'no',
      location: 'no',
      directories: 'no',
      status: 'no',
      menubar: 'no',
      scrollbars: 'yes',
      copyhistory: 'no',
      resizable: 'yes'
    }

    return Object.keys(windowFeatures).map(key => `${key}=${windowFeatures[key]}`).join(',')
  }
}
