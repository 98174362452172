import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['query', 'activity', 'customers']

  beforePerform(element, reflex) {
    this.activityTarget.hidden = false
  }

  perform(event) {
    event.preventDefault()
    this.stimulate('SalesOrderCustomerReflex#perform', this.queryTarget.value)
  }

  handleCustomerSelected(event) {
    this.stimulate('SalesOrderCustomerReflex#customer_selected', this.queryTarget.value, event.target.value)
  }
}
