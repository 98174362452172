import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['message', 'icon', 'continue'];

  connect() {
    const _this = this;

    let timeElapsed = 0;
    const totalTime = 20;
    const messages = window.Skutally.i18n.demoLobbyMessages;
    const showNewMessagesEvery = totalTime / (messages.length - 1);

    let tick = function() {
      timeElapsed += 1;

      let currentMessage = _this.messageTarget.innerHTML;
      let newMessage = messages[Math.floor(timeElapsed / showNewMessagesEvery)];

      if (currentMessage !== newMessage) {
        _this.messageTarget.classList.remove('delay-1s', 'fadeIn', 'zoomIn');
        _this.messageTarget.classList.add('fadeOut');
        setTimeout(function() {
          _this.messageTarget.innerHTML = newMessage;
          _this.messageTarget.classList.remove('fadeOut');

          if (timeElapsed >= totalTime) {
            _this.messageTarget.classList.add('fadeIn');
          } else {
            _this.messageTarget.classList.add('zoomIn');
          }
        }, 500);
      }

      if(timeElapsed >= totalTime) {
        _this.iconTarget.classList.add('text-success');
        _this.iconTarget.classList.remove('delay-1s', 'fadeIn');
        _this.iconTarget.classList.remove('text-muted');
        _this.iconTarget.classList.add('bounce');
        _this.iconTarget.getElementsByTagName('i')[0].classList.add('fa-check');
        _this.iconTarget.getElementsByTagName('i')[0].classList.remove('fa-spinner', 'fa-pulse');

        _this.continueTarget.classList.remove('d-none');
        _this.continueTarget.classList.add('fadeIn');
      } else {
        setTimeout(tick, 1000);
      }
    }

    setTimeout(tick, 1000);
  }
}
