import { Controller } from "stimulus";
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = ['progressBar']

  connect() {
    this.initChannel(this.context.element.dataset.id, this.context.element.dataset.importCompleteUrl);
  }

  initChannel(id, importCompleteUrl) {
    const that = this;

    consumer.subscriptions.create({ channel: "PriceEventImportChannel", id: id }, {
      received(data) {
        that.progressBarTarget.style.width = `${data.percentage}%`;

        if(data.percentage === 100) {
          setTimeout(() => {
            Turbo.visit(importCompleteUrl, { action: 'replace' });
          }, 1000);
        }
      }
    })
  }
}
