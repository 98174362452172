import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['quantitySelect', 'totalQuantityDisplay', 'unpickedShow', 'completeBtn'];
  static values = { totalToFulfill: Number }

  connect() {
    this.completeBtnTargets.forEach((element, index) => {
      element.hidden = true
    })
    this.updateQuantity();
  }

  updateQuantity() {
    if (!this.totalQuantityDisplayTarget) return;

    let sum = this.quantitySelectTargets
                  .map(select => parseInt(select.value))
                  .reduce((sum, x) => sum + x);


    let hideUnpicked = (sum == this.data.get('totalToFulfillValue'))

    this.unpickedShowTargets.forEach((element, index) => {
      element.hidden = hideUnpicked;
    })

    this.completeBtnTargets.forEach((element, index) => {
      element.hidden = !hideUnpicked;
    })

    this.totalQuantityDisplayTargets.forEach(target => target.innerHTML = sum);
  }
}
