import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'panel'
  ]

  showPanel(event) {
    const panelToShow = event.currentTarget.dataset.panel;

    this.panelTargets.forEach(panelTarget => {
      if (panelTarget.dataset.panel === panelToShow) {
        panelTarget.classList.add(panelTarget.dataset.panelClassActive);
        panelTarget.classList.remove(panelTarget.dataset.panelClassInactive);
      } else {
        panelTarget.classList.add(panelTarget.dataset.panelClassInactive);
        panelTarget.classList.remove(panelTarget.dataset.panelClassActive);
      }
    })
  }
}
