import { Controller } from "stimulus";
import CableReady from 'cable_ready';
import consumer from '../channels/consumer';

export default class extends Controller {

  connect() {
    const controller = this;
    this.subscription = undefined;

    this.subscription = consumer.subscriptions.create({
      channel: 'FulfillmentsChannel',
      account_id: this.context.element.dataset.accountId
    }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations)
          console.log(data.operations)
        // Turbolinks.visit(controller.context.element.dataset.reloadUrl, { action: 'replace' });
      }
    })
  };

  disconnect() {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription);
    }
  };
}
