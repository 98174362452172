import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["plan"]

  showPlan(event) {
    const planId = event.currentTarget.dataset.planId;

    this.planTargets.forEach((plan) => {
      if(plan.dataset.id == planId) {
        plan.classList.replace('d-none', 'd-block');
      } else {
        plan.classList.replace('d-block', 'd-none');
      }
    })
  }
}
