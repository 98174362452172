import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    window.addEventListener('scanning', this.scan);
  }

  disconnect() {
    window.removeEventListener('scanning', this.scan);
  }

  scan(event) {
    $('#warehouse-tote-scanner-form').find('.scan_sku').val(event.value);
    $('#form-submit').trigger('click');
    $('.scan-loader .adjustment-loader').show();
  }
}
