
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['countrySelect', 'stateSelect', 'stateSelectDiv', 'stateInputDiv', 'stateInput'];

  handleCountryChange() {
    this.populateStates(this.countrySelectTarget.value);
  }

  populateStates(countryCode) {
    const controller = this;

    fetch(`/states/${countryCode}.json`).then((r) => r.json()).then((data) => {
      const selectBox = controller.stateSelectTarget;
      const inputString = controller.stateInputTarget;
      selectBox.innerHTML = '';

      const inputDiv = controller.stateInputDivTarget;
      const selectDiv = controller.stateSelectDivTarget;
      if (data.length == 0) {
        inputDiv.hidden = false;
        inputString.disabled = false;
        selectDiv.hidden = true;
        selectBox.disabled = true;
      } else {
        inputDiv.hidden = true;
        inputString.disabled = true;
        selectDiv.hidden = false;
        selectBox.disabled = false;

        data.forEach(item => {
          const opt = document.createElement('option');
          opt.value = item.code;
          opt.innerHTML = item.name;

          selectBox.appendChild(opt);
        });
      }
    });
  }
}
