import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['originLocationSelect', 'destinationLocationSelect'];
  static values = {
    url: String
  }

  handleOriginLocationChange() {
    this.locationChange(this.originLocationSelectTarget.value);
  }

  locationChange(originLocationId) {
    const controller = this;

    fetch(controller.data.get('url')).then((r) => r.json()).then((data) => {
      const selectBox = controller.destinationLocationSelectTarget;
      selectBox.innerHTML = '';

      data.forEach(item => {
        const opt = document.createElement('option');
        opt.value = item.id;
        opt.innerHTML = item.name;

        if(opt.value != originLocationId) {
          selectBox.appendChild(opt);
        }
      });
    });
  }
}
